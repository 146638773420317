var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { newScanRow } from "@/services/datagrid";
import { getScanBoxItems } from "@/services/item";
import { Component, Vue } from "vue-property-decorator";
import VSelect from "vue-select";
import SelectScanOrderItemLabel from "../../views/elements/select/SelectScanOrderItemLabel.vue";
import Datagrid from "./datagrid.vue";
let DataGrid = class DataGrid extends Vue {
    constructor() {
        super(...arguments);
        this.providedRows = this.selectedWindow.output
            .Data.Rows;
        this.search = {};
        this.searchValue = "";
        this.filter = {
            options: [],
        };
        this.newFetchData = "";
        this.loading = false;
        this.columns = this.selectedWindow.output.Data.Columns;
    }
    get primaryKey() {
        const primarykeyColumn = Object.values(this.columns).find((column) => column.IsPrimaryKey === true);
        return primarykeyColumn;
    }
    get translations() {
        return this.$store.state.translations;
    }
    get selectedWindow() {
        return this.$store.getters.selectedWindow;
    }
    get selectedWindowType() {
        return this.$store.getters.selectedWindowType;
    }
    rowsUpdated(rows) {
        this.providedRows = rows;
    }
    async submit(event) {
        const requestData = {
            subject: this.selectedWindow.output.Request.Subject,
            criteria: this.selectedWindow.output.Request.Criteria,
            search: this.search.value ?? event.label ?? this.search,
        };
        const result = await newScanRow(requestData);
        const row = result[0];
        const Criteria = {
            [this.primaryKey.Name]: row[this.primaryKey.Name].Value,
        };
        const Values = {};
        for (const prop of Object.keys(row)) {
            if (prop === this.primaryKey.Name)
                continue;
            Values[prop] = row[prop].Value;
        }
        this.$store.state[this.selectedWindowType].changedRows.push({
            Criteria,
            Values,
        });
        this.providedRows.push({ ...row });
        this.search = {};
    }
    async fetchOptions(event) {
        this.searchValue = event;
        if (this.loading) {
            this.newFetchData = event;
            return;
        }
        this.loading = true;
        const requestData = await getScanBoxItems({
            searchValue: this.searchValue,
        });
        this.filter.options;
        const newOptions = [];
        for (const searchResult of requestData) {
            newOptions.push({
                label: searchResult.Text,
                value: searchResult.Value,
                type: searchResult.Attributes.Type,
                stock: searchResult.Attributes.Stock,
            });
        }
        this.filter.options = newOptions;
        this.loading = false;
        if (this.newFetchData !== null) {
            const newFetchData = this.newFetchData;
            this.newFetchData = null;
            await this.fetchOptions(newFetchData);
        }
    }
};
DataGrid = __decorate([
    Component({
        components: {
            Datagrid,
            VSelect,
            SelectScanOrderItemLabel,
        },
    })
], DataGrid);
export default DataGrid;
